import React from 'react'
import styled from 'styled-components'


const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -164px;
`;

const Title = styled.h2`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Lato;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
`

const SubTitle = styled.h3`
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Lato;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 50px;
`

const ImportantDisclosure = styled.section`
  border-radius: 20px;
  border: 1px solid var(--Light-Blue-2, #C5DCFB);
  background: var(--Light-Blue, #F8FBFF);
  color: var(--Black, #000);
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px;
padding:30px;
max-width: 1060px;
`

const BoldText = styled.p`
  font-weight:bold;
  display:inline-block;
  margin:0;
`

const Main = styled.div`
  display:flex;
  justify-content: center;
  margin-top: 30px;
`




const HomeToolkit = () => {
  return (
    <>
      <Header>
        <Title>My Home Toolkit</Title>
        <SubTitle>
          Connecting You With Helpful Service Providers
        </SubTitle>
      </Header>

      <Main>
      <ImportantDisclosure>
        <BoldText>Important Disclosure:</BoldText> These resources are being provided to assist in identifying
        potential service providers.  The Reverse Mortgage Servicing Department is not responsible for the content or services provided by the listed entities, nor are they endorsed, published by, or otherwise associated with the Reverse Mortgage Servicing Department. The logos and other information belong to the entities listed below and are subject to all applicable copyright, trademark, 
        and other proprietary rights of those entities.
        </ImportantDisclosure>
      </Main>
    </>
  )
}

export default HomeToolkit
